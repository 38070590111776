export const SANDBOX_MODE = process.env.REACT_APP_SANDBOX_MODE == "true";
// export const SANDBOX_BOOKING_DATA = {
//   bookingStations: [],
//   startTime: new Date(),
//   birthday: new Date(1990, 1, 1),
//   firstName: "John",
//   lastName: "スミス",
//   email: "contact@hololounge.jp",
//   phone: "８１０８７９７２０１７３４",
// };

export const SANDBOX_BOOKING_DATA = {
  bookingStations: [],
  startTime: new Date(2023, 6, 7, 14),
  birthday: new Date(1990, 1, 1),
  firstName: "Joe",
  lastName: "Boris",
  email: "josephdboris@gmail.com",
  phone: "15134778642",
};
